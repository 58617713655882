














































import {Component, Prop, Vue} from "vue-property-decorator";
import {userNamespace} from '@store/user';
import {
  getUserCertificates,
  Certificate,
  createDetachedSignature,
  createHash,
  isValidSystemSetup
} from 'crypto-pro';
import http from "@/http";
import {format} from 'date-fns'
import {IUser} from "@store/user/Interface";
import {documentsNamespace} from "@store/documents";
import {DocumentsActionTypes} from "@store/documents/Types";
import {IDocument} from "@store/documents/Interface";

@Component({
  name: 'EcpFormModal'
})
export default class EcpFormModal extends Vue {
  @userNamespace.State('user') public user!: IUser;

  @documentsNamespace.Action(DocumentsActionTypes.A_SIGN_UPLOAD)
  private signUpload!: (params:{sign:string,user_id:number,document_id:number}) => Promise<any>

  @Prop({type: Boolean, default: false})
  private show!: Boolean

  @Prop({type: Array, required:true})
  private documents!: IDocument[]

  get documentIds(){
    return this.documents.map(el => el.id)
  }

  private certThumbprint = ''
  protected certs: Certificate[] = []
  private signs = []
  private signed = 0

  private activeTabName: string = 'ecp'
  private signLoading: boolean = false
  private pluginLoading: boolean = false

  protected noCertLoad = false

  async startSign(){
    this.signLoading = true

    try {
      for (const document_id of this.documentIds){
        await this.signFile(document_id)
      }
      const message = this.singleFile ? 'Документ успешно подписан' : 'Документы успешно подписаны'
      this.$successNotify(message)
      this.$emit('filesSigned', this.documentIds)
    } catch (error) {
      console.error(error)
      this.$errorNotify(error?.response?.data?.message)
    } finally {
      this.signLoading = false
      this.$emit('close')
    }
  }

  async signFile(documentId) {
    const arrayBuffer = await this.getFileBuffer(documentId)
    const hash = await createHash(arrayBuffer)
    const sign = await createDetachedSignature(this.certThumbprint, hash)
    await this.signUpload({sign, user_id: this.user.id, document_id: documentId})

    if(this.singleFile){
      await this.getUserSigns()
      this.$emit('signedSingleFile')
    } else {
      this.signed++
    }
  }

  async getFileBuffer(documentId) {
    const res = await http.get(`documents/${documentId}/view`, {responseType: 'arraybuffer'})
    return res.data
  }

  get singleFile(){
    return this.documentIds.length === 1 ? this.documentIds[0] : null
  }

  get hasSigns(){
    if(!this.signFile) return false
    return Boolean(this.documents[0].signsCount)
  }

  async mounted() {
    this.pluginLoading = true

    this.hasSigns && this.singleFile && this.getUserSigns().catch((e) => console.log(e))

    try {

      if(!await isValidSystemSetup()){
        throw new Error('no valid settings')
      }

      let certs = await getUserCertificates()
      Array.isArray(certs) && (this.certs = certs)

    } catch (e) {
      this.noCertLoad = true
      console.error(e.message)
    }
    finally {
      this.pluginLoading = false
    }
  }

  async getUserSigns() {
    const response = await http.get(`documents/${this.singleFile}/signs_history`)
    this.signs = response.data.map((item) => {
      if (item.createdAt) {
        item.createdAt = format(new Date(item.createdAt), 'dd.MM.yyyy') + 'г.'
      }
      return item
    })
  }
}
